import { Controller } from "@hotwired/stimulus"

import Velocity from 'velocity-animate'

export default class extends Controller {
  static targets = []

  connect() {
  }

  scroll() {
    event.preventDefault()

    var target_url  = this.element.getAttribute('href')
    var target_page = target_url.split("#")[0]
    var target_anchor = target_url.split("#")[1]
    var source_page = `${location.pathname}${location.search}`

    if (target_page == '' || source_page == target_page) {
      if (window.history.replaceState) {
        window.history.replaceState({}, window.document.title, target_url)
      }

      var target = document.querySelector(`[name='${target_anchor}']`)

      Velocity(target, 'scroll', {
        duration: 900,
        easing: 'ease-in-out'
      })
    }
  }
}

import { config, library, dom } from '@fortawesome/fontawesome-svg-core'

import {
  faLightbulb, faUser, faGlobeEurope, faDesktop, faUserEdit, faCalendarCheck,
  faFileAlt, faUserCircle, faInbox, faStar, faPlusCircle, faArrowCircleUp,
  faCalendar, faUsers, faTag, faRotate, faUpload, faFileInvoice, faBell, faThList, faMapMarkerAlt,
  faMapPin, faCalendarAlt, faWrench, faInfoCircle, faPalette, faThumbtack, faChartLine,
  faExclamationCircle, faEnvelope, faRepeat, faMoneyBill, faClock, faPhone, faComment, faCode,
  faArrowRightArrowLeft, faCheck, faCircleCheck, faCircleXmark, faPercent, faStamp, faLink, faBuilding,
  faCreditCard, faGear, faDownload, faFileExcel, faCircle, faRocket, faLock, faGlobe, faServer, faSync, faUserShield, faShieldAlt,
  faTachometerAlt, faTablet, faCalendarPlus, faTimes, faShield, faFingerprint, faKey, faDharmachakra, faToriiGate, faEllipsisH,
  faTimesCircle, faExchangeAlt, faCheckCircle, faPauseCircle, faCheckDouble, faRandom, faFlagCheckered, faPlayCircle,
  faExclamationTriangle, faRedo, faEdit, faMoneyBillWave, faCaretDown,
} from '@fortawesome/free-solid-svg-icons'
import {
  faFacebookF, faGoogle,
} from '@fortawesome/free-brands-svg-icons'

library.add(
  faLightbulb, faUser, faGlobeEurope, faDesktop, faUserEdit, faCalendarCheck,
  faFileAlt, faUserCircle, faInbox, faStar, faPlusCircle, faArrowCircleUp,
  faCalendar, faUsers, faTag, faRotate, faUpload, faFileInvoice, faBell, faThList, faMapMarkerAlt,
  faMapPin, faCalendarAlt, faWrench, faInfoCircle, faPalette, faThumbtack, faChartLine,
  faExclamationCircle, faEnvelope, faRepeat, faMoneyBill, faClock, faPhone, faComment, faCode,
  faArrowRightArrowLeft, faCheck, faCircleCheck, faCircleXmark, faPercent, faStamp, faLink, faBuilding,
  faCreditCard, faGear, faDownload, faFileExcel, faCircle, faRocket, faLock, faGlobe, faServer, faSync, faUserShield, faShieldAlt,
  faTachometerAlt, faTablet, faCalendarPlus, faTimes, faShield, faFingerprint, faKey, faDharmachakra, faToriiGate, faEllipsisH,
  faTimesCircle, faExchangeAlt, faCheckCircle, faPauseCircle, faCheckDouble, faRandom, faFlagCheckered, faPlayCircle,
  faExclamationTriangle, faRedo, faEdit, faMoneyBillWave, faCaretDown,
  faFacebookF, faGoogle,
)

config.mutateApproach = 'sync'
dom.watch()

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["feed", "cache", "notification"];

  connect() {
    this.hiddenClasses = ["opacity-0", "-translate-y-8", "-mt-[calc(1rem+1px)]", "md:-mt-[calc(2rem+1px)]", "h-0"];
    this.visibleClasses = ["opacity-100", "translate-y-0", "mt-0", "h-auto"];

    // add the first 3 notifications on sm resolution, 6 on larger resolutions
    const notificationsToShow = window.innerWidth < 640 ? 3 : 6;
    this.notificationTargets.slice(0, notificationsToShow).forEach((notification) => {
      this.feedTarget.appendChild(notification);
      notification.classList.remove("hidden");
      notification.classList.remove(...this.hiddenClasses);
      notification.classList.add(...this.visibleClasses);
    });

    this.startAnimation();
    this.addResizeListener();
  }

  addResizeListener() {
    window.addEventListener('resize', () => {
      const notificationsToShow = window.innerWidth < 640 ? 3 : 6;
      const feed = this.feedTarget;
      const cache = this.cacheTarget;

      // Remove all notifications from feed
      while (feed.firstChild) {
        feed.firstChild.classList.remove(...this.visibleClasses);
        feed.firstChild.classList.add(...this.hiddenClasses);
        cache.appendChild(feed.firstChild);
      }

      // Add appropriate number of notifications
      this.notificationTargets.slice(0, notificationsToShow).forEach((notification) => {
        feed.appendChild(notification);
        notification.classList.remove("hidden");
        notification.classList.remove(...this.hiddenClasses);
        notification.classList.add(...this.visibleClasses);
      });
    });
  }

  startAnimation() {
    this.animationInterval = setInterval(() => {
      this.animateNotifications();
    }, 5000);
  }

  animateNotifications() {
    const feed = this.feedTarget;
    const cache = this.cacheTarget;
    const nextNotification = cache.querySelector("[data-fake-notifications-target='notification']");

    if (nextNotification) {
      // Prepare and animate new notification
      nextNotification.classList.remove("hidden");
      nextNotification.classList.add(...this.hiddenClasses);
      feed.prepend(nextNotification);

      void nextNotification.offsetWidth;

      nextNotification.classList.remove(...this.hiddenClasses);
      nextNotification.classList.add(...this.visibleClasses);

      // Remove last notification if needed
      // Remove last notification if needed
      const feedNotifications = Array.from(feed.children);
      const maxNotifications = window.innerWidth < 640 ? 3 : 6;
      if (feedNotifications.length > maxNotifications) {
        const lastNotification = feedNotifications.at(-1);

        // Explicitly add fade-out classes
        lastNotification.classList.remove("opacity-100", "translate-y-0", "h-auto");
        lastNotification.classList.add("opacity-0");

        setTimeout(() => {
          lastNotification.classList.add("hidden");
          cache.appendChild(lastNotification);
        }, 1000);
      }
    }
  }

  disconnect() {
    clearInterval(this.animationInterval);
  }
}

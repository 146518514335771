import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['body', 'nav', 'burger']

  connect() {
  }

  disconnect() {
    this.close()
  }

  open() {
    event.stopPropagation()
    this.burgerTarget.dataset.action = 'click->off-canvas#close'
    this.navTarget.classList.add('translate-x-0')
    this.bodyTargets.forEach((el) => {
    //   el.classList.add('-translate-x-full')
      el.dataset.action = 'click->off-canvas#close'
    })
  }

  close() {
    if (event != undefined) {
      event.stopPropagation()
    }
    this.burgerTarget.dataset.action = 'click->off-canvas#open'
    this.navTarget.classList.remove('translate-x-0')
    this.bodyTargets.forEach((el) => {
    //   el.classList.remove('-translate-x-full')
      el.dataset.action = ''
    })
  }
}

// Require defaults
require("@rails/ujs").start()
import "@hotwired/turbo-rails"

// Font Awesome
import "../src/fontawesome/sitex"

// Stimulus
import "../controllers/site"

// Fonts
// import "@fontsource/lato/400.css"
// import "@fontsource/lato/400-italic.css"
// import "@fontsource/lato/700.css"
// import "@fontsource/lato/700-italic.css"
// import "@fontsource/lato/900.css"

document.addEventListener('turbo:load', function(event) {
  if (window.ga != undefined) {
    ga('set', 'location', location.href.split('#')[0])
    ga('send', 'pageview', {"title": document.title})
  } else if (window._gaq != undefined) {
    _gaq.push(['_trackPageview'])
  } else if (window.pageTracker != undefined) {
    pageTracker._trackPageview()
  }
})
